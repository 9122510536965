import React, { Fragment, useEffect } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { Card } from '@mui/material';
import MynyfyHeader from '../../components/MynyfyHeader';
import MynyfyButton from '../../components/MynyfyButton';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import productOrderService from '../../services/productOrderService';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import _ from 'lodash';
import MynyfyInput from '../../components/MynyfyInput';
import productService from '../../services/productService';
import { OrderStatus, ProductMaterialType } from '../../utils/enums';
import { ReactComponent as VegIcon } from '../../assets/svg/veg.svg';
import { ReactComponent as NonVegIcon } from '../../assets/svg/nonVeg.svg';
import theme from '../../utils/theme';
import { AddRounded, RemoveRounded } from '@mui/icons-material';
import { addToBag, decrementHandler, incrementHandler } from './TableOrderHelper';
import MynyfySpan from '../../components/MynyfySpan';
import validate from '../../utils/validate';
import './orders.scss';
import { productsMergeHandler } from '../Products/ProductHelper';

let VALIDATOR = {
  selectedColor: [(value) => (value ? null : 'Select product color')],
  selectedSize: [(value) => (value ? null : 'Select product size')],
};

const TableOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useLocation();

  const [isLoading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [products, setProducts] = useState(null);
  const [newProducts, setNewProducts] = useState([]);
  const [priceDetails, setPriceDetails] = useState(null);
  const [isClicked, setClicked] = useState(false);
  const [actualProducts, setActualProducts] = useState(null);

  useEffect(() => {
    if (route.state?.order) {
      productOrderService.getProductOrderById(route.state?.order?.orderId).then((res) => {
        if (res.success) {
          if (res.data.status == OrderStatus.ACTIVE) {
            setOrderDetails(res.data);
            setPriceDetails(res.data?.priceDetails || {});
          } else {
            navigate('/');
            dispatch(
              snackbarInfo({
                type: 'warning',
                open: true,
                message: res.data?.orderId + ' Order has been completed',
              })
            );
          }
        } else {
          dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
        }
      });
      let params = productsParamsHandler();
      if (!_.isEmpty(params)) getProducts(params);
    } else navigate('/');
  }, []);

  const productsParamsHandler = () => {
    let org = route.state?.storeDetails;
    let data = {
      status: 'ACTIVE',
      brands: [],
      seller: org?._id,
      state: org?.state?._id,
      city: org?.city?._id,
      area: org?.area?._id,
    };

    let sellerCats = org.ownerId.categories.filter((e) => e.code === 'Category');
    data.category = sellerCats.map((res) => res._id);

    if (!_.isEmpty(org.productInActiveSubCategories)) {
      data.notRequiredSubCats = org.productInActiveSubCategories;
    }

    return data;
  };

  const getProducts = (params) => {
    productService.getProducts(params).then((res) => {
      if (res.success) {
        productsMergeHandler({ products: res.data, setActualProducts, setProducts });
      } else {
        dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
      }
    });
  };

  const searchHandler = (key) => {
    setSearchInput(key);
    if (key) {
      let reqProducts = actualProducts.filter((e) =>
        e.name.toLowerCase().includes(key.toLowerCase())
      );
      setProducts(reqProducts);
    } else {
      setProducts(actualProducts);
    }
  };

  const bagHandler = (item, key) => {
    let reqObj = {
      productDetails: item,
      newProducts,
      setNewProducts,
      priceDetails: orderDetails.priceDetails,
      setPriceDetails,
      dispatch,
    };
    if (key === 'inc') incrementHandler(reqObj);
    else if (key === 'dec') decrementHandler(reqObj);
    else if (key === 'add') {
      if (!_.isEmpty(item.size) || !_.isEmpty(item.color)) {
        // TODO productNavigationHandler(item);
      } else addToBag(reqObj);
    }
  };

  const submitOrder = () => {
    searchHandler('');
    setClicked(true);
    let body = { products: newProducts };
    let isErrors = validate(body, VALIDATOR);
    if (_.isEmpty(isErrors)) {
      productOrderService
        .editProductOrder(body, orderDetails._id)
        .then((res) => {
          if (res.success) {
            setOrderDetails(res.data);
            setPriceDetails(res.data?.priceDetails);
            setNewProducts([]);
            dispatch(
              snackbarInfo({
                open: true,
                type: 'success',
                message: 'New Items added to your current order',
              })
            );
          } else {
            setNewProducts(res.message?.products);
            dispatch(snackbarInfo({ open: true, type: 'error', message: res.message?.message }));
          }
        })
        .finally(() => setClicked(false));
    } else {
      dispatch(snackbarInfo({ open: true, type: 'error', message: 'Please check the details' }));
      setClicked(false);
    }
  };

  const productsComponent = (item, i, key) => {
    return (
      <div className='rowSB' style={{ padding: '5px 0px' }} key={item._id || i}>
        <div style={{ flex: 0.6 }}>
          <MynyfySpan title={item.name} />
          {key == 'new' ? (
            (item.remainingCount || item.remainingCount === 0) &&
            item.remainingCount < item.count ? (
              <MynyfyText
                title={
                  item.remainingCount === 0
                    ? 'Out of Stock'
                    : `Only ${item.remainingCount} ${
                        item.remainingCount > 1 ? 'products' : 'product'
                      } available`
                }
                error
                small
              />
            ) : null
          ) : null}
        </div>
        <MynyfyText title={item.count} center style={{ flex: 0.2 }} />
        <MynyfyText title={item.price * item.count} style={{ flex: 0.2, textAlign: 'end' }} />
      </div>
    );
  };

  return orderDetails ? (
    <Fragment>
      <MynyfyHeader
        noBack
        title={orderDetails?.orderId ? 'Active Order - ' + orderDetails?.orderId : 'Order Details'}
        customElememt={() => (
          <div style={{ textAlign: 'right' }}>
            <MynyfyText title={'Table - ' + orderDetails?.dineTableNumber} bold h5 />
          </div>
        )}
      />
      {isLoading ? (
        <MynyfyLoader />
      ) : _.isEmpty(orderDetails) ? (
        <MynyfyEmptyMsg
          message={
            (orderDetails?.orderId
              ? 'Order - ' + orderDetails?.orderId + ' is'
              : 'Order Details are ') + ' Not Found'
          }
        />
      ) : (
        <div id='TableOrder'>
          <div className='mainSection'>
            <div>
              <Card style={{ margin: 10 }}>
                <div className='rowSB' style={{ backgroundColor: '#e6e6e6', padding: '10px 15px' }}>
                  <MynyfyText title={'Item'} bold pBig style={{ flex: 0.6 }} />
                  <MynyfyText title={'Qty'} bold pBig center style={{ flex: 0.2 }} />
                  <MynyfyText title={'Amount'} bold pBig style={{ flex: 0.2, textAlign: 'end' }} />
                </div>
                <div style={{ padding: '5px 15px' }}>
                  {orderDetails.products?.map((res, i) => productsComponent(res, i, 'products'))}

                  {_.isEmpty(orderDetails.newProducts) ? null : (
                    <div style={{ borderBottom: '1px dashed grey', margin: '7px 0px' }} />
                  )}

                  {orderDetails.newProducts?.map((newOfNew, i) => (
                    <Fragment key={i}>
                      {newOfNew?.map((res, j) => productsComponent(res, j, 'newProducts'))}
                      {orderDetails.newProducts.length == i + 1 ? null : (
                        <div style={{ borderBottom: '1px dashed grey', margin: '7px 0px' }} />
                      )}
                    </Fragment>
                  ))}

                  {_.isEmpty(newProducts) ? null : (
                    <MynyfyText
                      title={'Newly Added Items'}
                      bold
                      pSmall
                      style={{ margin: '7px 0px' }}
                    />
                  )}

                  {newProducts?.map((res, i) => productsComponent(res, i, 'new'))}
                </div>
                <div
                  className='rowSB section'
                  style={{ backgroundColor: '#e6e6e6', padding: '10px 15px' }}>
                  <div>
                    <MynyfySpan title={'Total'} bold />
                    <MynyfySpan title={' (Including GST)'} bold small />
                  </div>
                  <MynyfyText title={priceDetails?.totalPrice} bold />
                </div>
              </Card>
            </div>
            <MynyfyText
              title={'*Inform Staff for Check/Bill'}
              pSmall
              style={{ padding: '0px 11px 5px', textAlign: 'right' }}
            />
            <div style={{ margin: 5 }}>
              {_.isEmpty(newProducts) ? null : (
                <MynyfyButton
                  label={'Save Order'}
                  className='btn'
                  center
                  onClick={() => {
                    if (!_.isEmpty(newProducts)) submitOrder();
                  }}
                  disabled={isClicked}
                />
              )}
            </div>
            {orderDetails.paymentStatus !== 'PENDING' ? null : (
              <Fragment>
                <MynyfyText
                  title={'Add more items to your order'}
                  pBig
                  bold
                  style={{ padding: '5px 11px' }}
                />
                <MynyfyInput
                  placeHolder='Search item to add'
                  name='searchProduct'
                  fullWidth={true}
                  value={searchInput}
                  onChange={(e) => searchHandler(e.target.value)}
                  style={{ margin: '10px 10px 0px' }}
                  disabled={isClicked}
                />

                {products ? (
                  _.isEmpty(products) ? (
                    <MynyfyEmptyMsg paddingTop='10px' message={'No item found'} />
                  ) : (
                    products.map((item, i) => (
                      <div id='CreateOrder' key={i}>
                        <div className='foodProductContainer'>
                          <div className='row foodProductSubContainer'>
                            <div className='foodProductLeftContainer'>
                              <div className='row' style={{ alignItems: 'flex-start' }}>
                                {item.materialType ? (
                                  <div style={{ margin: '5px 8px -4px 0px' }}>
                                    {item.materialType == ProductMaterialType.NON_VEGETARIAN ? (
                                      <NonVegIcon width={15} height={15} />
                                    ) : (
                                      <VegIcon width={15} height={15} />
                                    )}
                                  </div>
                                ) : null}
                                <MynyfyText title={item.name} bold h5 />
                              </div>
                              <div className='row' style={{ margin: '10px 0px 0px' }}>
                                <MynyfyText
                                  title={theme.RUPEE + item.actualPrice}
                                  style={{
                                    textDecorationLine:
                                      item.price && item.actualPrice !== item.price
                                        ? 'line-through'
                                        : 'unset',
                                  }}
                                  small={
                                    item.price && item.actualPrice !== item.price ? true : false
                                  }
                                  bold={
                                    item.price && item.actualPrice !== item.price ? false : true
                                  }
                                />
                                {item.actualPrice == item.price || !item.price ? null : (
                                  <MynyfyText
                                    title={theme.RUPEE + item.price}
                                    style={{ margin: '0px 5px' }}
                                    bold
                                  />
                                )}
                              </div>
                            </div>
                            <div style={{ flex: 1, textAlign: 'end' }}>
                              <div style={{ position: 'relative' }}>
                                {newProducts?.find((e) => e.productId == item._id) ? (
                                  <div
                                    className='rowSB addProductConatiner'
                                    style={{ padding: '8.5px 10px' }}>
                                    <div onClick={() => bagHandler(item, 'dec')}>
                                      <RemoveRounded
                                        fontSize='small'
                                        className='productIcon link'
                                      />
                                    </div>
                                    <MynyfyText
                                      title={
                                        newProducts?.find((e) => e.productId == item._id)?.count ||
                                        0
                                      }
                                      h6
                                      bold
                                      center
                                      style={{ userSelect: 'none', cursor: 'default' }}
                                    />
                                    <div onClick={() => bagHandler(item, 'inc')}>
                                      <AddRounded fontSize='small' className='productIcon link' />
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className='addProductConatiner link'
                                    style={{
                                      backgroundColor: item.count
                                        ? item.count - item.ordersCount > 0
                                          ? theme.LIGHT_COLOR
                                          : theme.MUTED_COLOR
                                        : theme.LIGHT_COLOR,
                                    }}
                                    onClick={() => {
                                      if (
                                        item.count
                                          ? item.count - item.ordersCount > 0
                                            ? false
                                            : true
                                          : false
                                      ) {
                                        // it is disabled
                                      } else {
                                        bagHandler(item, 'add');
                                      }
                                    }}>
                                    {(
                                      item.count
                                        ? item.count - item.ordersCount > 0
                                          ? false
                                          : true
                                        : false
                                    ) ? (
                                      <MynyfyText
                                        title={'Out Of Stock'}
                                        small
                                        bold
                                        center
                                        color={theme.ERROR}
                                      />
                                    ) : (
                                      <MynyfyText
                                        title={'Add'}
                                        primary
                                        bold
                                        center
                                        style={{ textTransform: 'uppercase' }}
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )
                ) : null}
              </Fragment>
            )}
          </div>
        </div>
      )}
    </Fragment>
  ) : (
    <MynyfyLoader />
  );
};

export default TableOrder;
