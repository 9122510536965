import moment from 'moment';
import _ from 'lodash';
import theme from '../utils/theme';
import { useSelector } from 'react-redux';

const OrderInvoice = ({ order, orgAddress, taxDetails }) => {
  const buyer = useSelector((state) => state.user?.user);

  const starContent = ({ content, contentStyles, styles }) => {
    return `<div class="rowC" style="${styles || ''}">
        <p style="font-size: 16px; margin-bottom: -8px">***</p>
        &ensp;
        <p style="${contentStyles || ''}">${content}</p>
        &ensp;
        <p style="font-size: 16px; margin-bottom: -8px">***</p>
      </div>`;
  };

  return `
    <!DOCTYPE html>
    <head>
      <style>
        * { margin: 0; padding: 0; }
        .taxCol1 {flex: 2; font-size: 15px;}
        .taxCol2 {flex: 2; font-size: 15px; text-align: right;}
        .taxCol3 {flex: 2; font-size: 15px; text-align: right;}
        .taxCol4 {flex: 2; font-size: 15px; text-align: right;}
        .taxCol5 {flex: 2; font-size: 15px; text-align: right;}
        .separator {border-bottom: 2px dashed gray; margin: 10px 0px;}
        .contentPadding {padding: 0px 15px;}
        .aboutOrder {padding-bottom: 5px;}
        .subHeading {width: 100px; font-size: 15px;}
        .row {display: flex; align-items: center;}
        .rowSB {display: flex; align-items: center; justify-content: space-between;}
        .rowC {display: flex; align-items: center; justify-content: center;}
        .sellerName {font-size: 20px; text-transform: uppercase; font-weight: bold;}
        .sellerRegisteredName {font-size: 16px; padding: 5px 0px;}
        .addressHeading {font-size: 16px; padding: 5px 0px 3px; display: inline-block; box-shadow: inset 0 -3px 0 0 currentColor;}
        .pageBreak {page-break-before: always;}
      </style>
    </head>
    <body>
      <div style="padding: 0px 20px;">
        <p class="sellerName" style="text-align: center;">${order?.seller.name}</p>
        <div class="rowSB" style="align-items: flex-start;">
        <div style="flex: 0.9;">
            <p class="addressHeading">Customer Details</p>
            <p class="sellerName">${
              buyer
                ? buyer?.firstName + ' ' + (buyer?.lastName || '')
                : order?.customerDetails?.name
            }</p>
            ${buyer?.email ? `<p class="sellerRegisteredName">${buyer?.email}</p>` : ''}
            ${
              order?.customerDetails?.mobile || buyer?.mobile
                ? `<p class="sellerRegisteredName">${
                    buyer?.mobile || order?.customerDetails?.mobile
                  }</p>`
                : ''
            }
            ${
              order?.fullAddress
                ? `<div>
                    ${order?.fullAddress?.lane1 ? `<span>${order?.fullAddress.lane1}, </span>` : ''}
                    ${order?.fullAddress?.lane2 ? `<span>${order?.fullAddress.lane2}, </span>` : ''}
                  </div>
                  <div style="padding: 5px 0px;">
                    ${
                      order?.fullAddress?.locality
                        ? `<span>${order?.fullAddress.locality}, </span>`
                        : ''
                    }
                    ${order?.fullAddress?.city ? `<span>${order?.fullAddress.city}, </span>` : ''}
                  </div>
                  <div>
                    ${order?.fullAddress?.state ? `<span>${order?.fullAddress.state} </span>` : ''}
                    ${
                      order?.fullAddress?.pincode
                        ? `<span> - ${order?.fullAddress.pincode}</span>`
                        : ''
                    }
                  </div>`
                : order?.customerDetails?.address
                ? `<p class="sellerRegisteredName" style="width: 75%">${order?.customerDetails?.address}</p>`
                : ''
            }
            ${
              order?.customerDetails?.gst
                ? `<p class="sellerRegisteredName">${order?.customerDetails?.gst}</p>`
                : ''
            }
          </div>
          <div style="text-align: right; flex:0.9;">
            <p class="addressHeading">Seller Details</p>
            <p class="sellerRegisteredName">${order?.seller.registeredName}</p>
            <p class="sellerRegisteredName">${
              (order?.seller?.ownerId?.profile?.firstName || '') +
              ' ' +
              (order?.seller?.ownerId?.profile?.lastName || '')
            }</p>
            ${
              orgAddress
                ? `<div>
                    ${orgAddress?.lane1 ? `<span>${orgAddress.lane1}, </span>` : ''}
                    ${orgAddress?.lane2 ? `<span>${orgAddress.lane2}, </span>` : ''}
                  </div>
                  <div style="padding: 6px 0px;">
                    ${orgAddress?.locality ? `<span>${orgAddress.locality}, </span>` : ''}
                    ${orgAddress?.city ? `<span>${orgAddress.city}, </span>` : ''}
                  </div>
                  <div>
                    ${orgAddress?.state ? `<span>${orgAddress.state} </span>` : ''}
                    ${orgAddress?.pincode ? `<span> - ${orgAddress.pincode}</span>` : ''}
                  </div>`
                : ''
            }
            <p class="sellerRegisteredName">Ph: ${order?.seller?.ownerId?.profile?.mobile}</p>
            <div>
              ${
                order?.seller?.documents?.find((e) => e.name == 'GST' && e.id)
                  ? `<p>GSTIN NO: ${order?.seller?.documents?.find((e) => e.name == 'GST').id}</p>`
                  : ''
              }
            </div>
          </div>
          
        </div>
        <div style="text-align: center;">
          ${starContent({
            content: 'Tax Invoice',
            contentStyles: 'font-size: 18px; padding-top: 5px;',
          })}
        </div>
        <div class="separator"></div>
        ${
          order.orderType
            ? `${starContent({
                content: order.orderType,
                contentStyles: 'font-size: 18px; text-align: center;',
                styles: 'margin: -5px 0px;',
              })}
              <div class="separator"></div>`
            : ''
        }
        <div class="contentPadding">
          <div class="aboutOrder row">
            <p class="subHeading">Order No</p>
            <p style="font-size: 15px"><span> : </span><span style="font-weight: bold">${
              order?.orderId
            }</span></p>
          </div>
          ${
            order?.invoice
              ? `<div class="aboutOrder row">
                  <p class="subHeading">Invoice No</p>
                  <p style="font-size: 15px;"> : ${order?.invoice}</p>
                </div>`
              : ''
          }
          ${
            order?.closedDate
              ? `<div class="aboutOrder row">
                  <p class="subHeading">Date & Time</p>
                  <p class="font-size: 15px;"> : ${moment(order?.closedDate).format(
                    'DD MMMM YYYY, hh:mm:ss A'
                  )}</p>
                </div>`
              : ''
          }
          ${
            order?.dineTableNumber
              ? `<div class="aboutOrder row">
                  <p class="subHeading">Table</p>
                  <p style="font-size: 15px;"> : ${order?.dineTableNumber}</p>
                </div>`
              : ''
          }
        </div>
        <div class="separator"></div>
        <div class="rowSB contentPadding">
          <p style="font-size: 15px; flex: 2.5;">Item</p>
          <p style="font-size: 15px; flex: 1;">HSN</p>
          <p style="font-size: 15px; flex: 1; text-align: center;">Qty</p>
          <p style="font-size: 15px; flex: 1; text-align: center;">GST</p>
          <div style="flex: 1.5; text-align: right;">
            <p style="font-size: 15px;">Rate</p>
            <p style="font-size: 15px;">(Inc. GST)</p>
          </div>
          <p style="font-size: 15px; flex: 1.5; text-align: right;">Discount</p>
          <div style="flex: 1.5; text-align: right;">
            <p style="font-size: 15px;">Amount</p>
            <p style="font-size: 15px;">(Inc. GST)</p>
          </div>
        </div>
        <div class="separator"></div>
        <div class="contentPadding">
          ${order?.deals
            ?.map(
              (res, i) =>
                `<div key=${i} class="rowSB">
                  <p style="font-size: 15px; flex: 2.5; text-transform: capitalize;">${
                    res.name || res.dealId?.name
                  }</p>
                  <p style="font-size: 15px; flex: 1; text-align: center;">--</p>
                  <p style="font-size: 15px; flex: 1; text-align: center;">1</p>
                  <p style="font-size: 15px; flex: 1; text-align: center;">${
                    (res.gst || 0) + '%'
                  }</p>
                  <p style="font-size: 15px; flex: 1.5; text-align: right;">${
                    theme.RUPEE + (res.price || res.dealId?.actualPrice).toFixed(2)
                  }</p>
                  <p style="font-size: 15px; flex: 1.5; text-align: right;">${
                    theme.RUPEE + (0).toFixed(2)
                  }</p>
                  <p style="font-size: 15px; flex: 1.5; text-align: right;">${
                    theme.RUPEE + (res.price || res.dealId?.actualPrice).toFixed(2)
                  }</p>
                </div>`
            )
            .join('')}
          ${order?.products
            ?.map(
              (res, i) =>
                `<div key=${i} class="rowSB" style="padding-bottom: ${
                  i == order?.products?.length - 1 ? 0 : 10
                }px;">
              <p style="font-size: 15px; flex: 2.5; text-transform: capitalize;">${
                res.name || res.productId?.name
              } ${' - ' + (res.productCode || res.productId?.variants?.[0]?.productCode || '')}</p>
              <p style="font-size: 15px; flex: 1;">${res.productId?.hsnCode || '--'}</p>
              <p style="font-size: 15px; flex: 1; text-align: center;">${res.count}</p>
              <p style="font-size: 15px; flex: 1; text-align: center;">${(res.gst || 0) + '%'}</p>
              <p style="font-size: 15px; flex: 1.5; text-align: right;">${
                theme.RUPEE + (res.price || res.productId?.variants?.[0]?.price || 0).toFixed(2)
              }</p>
              <p style="font-size: 15px; flex: 1.5; text-align: right;">${
                theme.RUPEE +
                (
                  res.count *
                  (res.discountedPrice || (res.discount && res.discountedPrice == 0)
                    ? (res.price || res.productId?.variants?.[0]?.price || 0) - res.discountedPrice
                    : 0)
                ).toFixed(2)
              }</p>
              <p style="font-size: 15px; flex: 1.5; text-align: right;">${
                theme.RUPEE +
                (
                  res.count *
                  (res.discountedPrice || (res.discount && res.discountedPrice == 0)
                    ? res.discountedPrice
                    : res.price || res.productId?.variants?.[0]?.price || 0)
                ).toFixed(2)
              }</p>
            </div>`
            )
            .join('')}
        </div>
        ${
          _.isEmpty(taxDetails)
            ? ''
            : `<div class="separator"></div>
              <p class="pageBreak" style="text-align: center;">Tax Details</p>
              <div class="separator"></div>
              <div class="rowSB contentPadding">
                <p class="taxCol1">Tax Desc</p>
                <p class="taxCol2">Taxable</p>
                <p class="taxCol3">SGST</p>
                <p class="taxCol4">CGST</p>
                <p class="taxCol5">Tax Amt</p>
              </div>
              <div class="separator"></div>
              <div class="contentPadding">
                ${taxDetails
                  ?.map(
                    (res, i) =>
                      `<div class="rowSB" key=${i} style="padding-bottom: ${
                        i == taxDetails?.length - 1 ? 0 : 10
                      }px;">
                    <p class="taxCol1">Output GST ${res.title} %</p>
                    <p class="taxCol2">${theme.RUPEE + res.taxableAmt.toFixed(2)}</p>
                    <p class="taxCol3">${theme.RUPEE + (res.taxAmt / 2).toFixed(2)}</p>
                    <p class="taxCol4">${theme.RUPEE + (res.taxAmt / 2).toFixed(2)}</p>
                    <p class="taxCol5">${theme.RUPEE + res.taxAmt.toFixed(2)}</p>
                  </div>`
                  )
                  .join('')}
              </div>
              <div class="separator"></div>

              <div class="contentPadding">
                <div class="rowSB">
                  <p class="taxCol1">Total</p>
                  <p class="taxCol2">${
                    theme.RUPEE +
                    taxDetails.reduce((acc, curr) => acc + curr.taxableAmt, 0).toFixed(2)
                  }</p>
                  <p class="taxCol3">${
                    theme.RUPEE +
                    (taxDetails.reduce((acc, curr) => acc + curr.taxAmt, 0) / 2).toFixed(2)
                  }</p>
                  <p class="taxCol4">${
                    theme.RUPEE +
                    (taxDetails.reduce((acc, curr) => acc + curr.taxAmt, 0) / 2).toFixed(2)
                  }</p>
                  <p class="taxCol5">${
                    theme.RUPEE + taxDetails.reduce((acc, curr) => acc + curr.taxAmt, 0).toFixed(2)
                  }</p>
                </div>
              </div>`
        }
        ${
          order?.priceDetails?.shippingCharges ||
          order?.priceDetails?.discount ||
          order?.priceDetails?.productsDiscount ||
          order?.priceDetails?.couponDiscount ||
          order?.priceDetails?.subscriptionPassDiscount ||
          order?.priceDetails?.serviceCharges
            ? `<div class="separator"></div>`
            : ''
        }
        <div class="contentPadding pageBreak">
          ${
            order?.priceDetails?.discount ||
            order?.priceDetails?.productsDiscount ||
            order?.priceDetails?.couponDiscount
              ? `<div class="rowSB" style="margin-top: 5px">
                <p>Discount</p>
                <p style="font-size: 15px;">${
                  theme.RUPEE +
                  (
                    (order?.priceDetails?.discount || 0) +
                    (order?.priceDetails?.productsDiscount || 0) +
                    (order?.priceDetails?.couponDiscount || 0)
                  )?.toFixed(2)
                }</p>
              </div>`
              : ''
          }
          ${
            order?.priceDetails?.shippingCharges
              ? `<div class="rowSB"  style="margin-top: 5px">
                  <p>Shipping Charges</p>
                  <p style="font-size: 15px;">${
                    theme.RUPEE + order?.priceDetails?.shippingCharges?.toFixed(2)
                  }</p>
                </div>`
              : ''
          }
          ${
            order?.priceDetails?.serviceCharges
              ? `<div class="rowSB" style="margin-top: 5px">
                  <p>Service Charges</p>
                  <p style="font-size: 15px;">${
                    theme.RUPEE + order?.priceDetails?.serviceCharges?.toFixed(2)
                  }</p>
                </div>`
              : ''
          }
          ${
            order?.priceDetails?.subscriptionPassDiscount
              ? `<div class="rowSB" style="margin-top: 5px">
                  <p>Subscription Pass</p>
                  <p style="font-size: 15px;">${
                    theme.RUPEE + order?.priceDetails?.subscriptionPassDiscount?.toFixed(2)
                  }</p>
                </div>`
              : ''
          }
        </div>
        <div class="separator"></div>
        <div class="rowSB contentPadding pageBreak">
          <p>Total Incl of GST</p>
          <p style="font-size: 15px; font-weight: bold;">${
            theme.RUPEE + (order?.priceDetails?.totalPrice || 0).toFixed(2)
          }</p>
        </div>
        <div class="separator"></div>
        ${starContent({
          content: 'Thank you, please vist again',
          contentStyles: 'font-size: 15px; text-align: center;',
          styles: 'padding-bottom: 20px;',
        })}
      </div>
    </body>`;
};

export default OrderInvoice;
