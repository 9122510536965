import _ from 'lodash';
import validate from '../../utils/validate';
import { fetchBagCalculation, fetchBagProducts } from '../../redux/actions/bag.action';
import { objectReplace, priceClaculation } from '../../utils/CommonMethods';
import { snackbarInfo } from '../../redux/actions/snackbar.action';

let VALIDATOR = {
  selectedColor: [(value) => (value ? null : 'Select product color')],
  selectedSize: [(value) => (value ? null : 'Select product size')],
};

export const addToBag = ({ productDetails, variant, bag, dispatch }) => {
  let qty = variant.count ? variant.count - variant.ordersCount : 'unlimited';
  if (qty == 'unlimited' || qty > 0) {
    let obj = {};
    if (!_.isEmpty(variant.size)) obj.selectedSize = variant.size;
    if (!_.isEmpty(variant.color)) obj.selectedColor = variant.color;
    let errors = validate(obj, VALIDATOR);
    if (_.isEmpty(errors)) {
      let productObj = {
        productId: productDetails._id,
        variantId: variant._id,
        count: 1,
        orderCount: variant.ordersCount,
        leftOverQuantity: qty,
        price: variant.price,
        gst: productDetails.gst?.data || 0,
        priceBeforeGst: priceClaculation({ price: variant.price, gst: productDetails.gst }),
        name: productDetails.name,
        brandId: productDetails.brand?._id,
        categoryId: productDetails.category?._id,
        subCategoryId: productDetails.subCategory?._id,
        state: productDetails.state?.map((res) => res._id),
        city: productDetails.city?.map((res) => res._id),
        area: productDetails.area?.map((res) => res._id),
        weightRange: variant?.weightRange,
        img: variant.image?.[0]?.url,
      };
      if (!_.isEmpty(variant.size)) productObj.size = variant.size;
      if (!_.isEmpty(variant.color)) productObj.color = variant.color;
      if (productDetails?.brandProductId) {
        productObj.brandProductId = productDetails?.brandProductId;
        productObj.brandVariantId = variant?.brandVariantId;
      }

      let clonedProducts = _.cloneDeep(bag?.products);
      if (_.isEmpty(clonedProducts)) {
        dispatch(fetchBagProducts([productObj]));
        totalCalculation({ dispatch, reqObj: [productObj] });
      } else {
        let reqProduct = clonedProducts.find(
          (e) => e.productId == productDetails._id && e.variantId == variant._id
        );
        if (reqProduct) {
          incrementHandler({ productDetails, variant, bag, dispatch });
        } else {
          clonedProducts.push(productObj);
          dispatch(fetchBagProducts(clonedProducts));
          totalCalculation({ dispatch, reqObj: clonedProducts });
        }
      }
    } else {
      let message = Object.values(errors);
      if (message.length > 1) message.unshift('Below fields are mandatory');
      dispatch(snackbarInfo({ type: 'warning', open: true, message }));
    }
  } else {
    dispatch(
      snackbarInfo({
        type: 'warning',
        open: true,
        message: qty <= 0 ? 'Currently this product is out of stock' : `Only ${qty} are available`,
      })
    );
  }
};

export const incrementHandler = ({ productDetails, variant, bag, dispatch }) => {
  let clonedProducts = _.cloneDeep(bag?.products);
  let reqProduct = clonedProducts.find(
    (e) => e.productId == productDetails._id && e.variantId == variant._id
  );
  if (reqProduct) {
    let qty = reqProduct.leftOverQuantity;
    if (qty == 'unlimited' || qty > reqProduct.count) {
      reqProduct.count = reqProduct.count + 1;
      let modifiedData = objectReplace(clonedProducts, reqProduct, 'variantId');
      dispatch(fetchBagProducts(modifiedData));
      totalCalculation({ dispatch, reqObj: modifiedData });
    } else {
      dispatch(snackbarInfo({ type: 'warning', open: true, message: `Only ${qty} are available` }));
    }
  }
};

export const decrementHandler = ({ productDetails, variant, bag, dispatch }) => {
  let clonedProducts = _.cloneDeep(bag?.products);
  let reqProduct = clonedProducts.find(
    (e) => e.productId == productDetails._id && e.variantId == variant._id
  );
  if (reqProduct) {
    if (reqProduct.count > 0) {
      reqProduct.count = reqProduct.count - 1;
      if (reqProduct.count > 0) {
        let modifiedData = objectReplace(clonedProducts, reqProduct, 'variantId');
        dispatch(fetchBagProducts(modifiedData));
        totalCalculation({ dispatch, reqObj: modifiedData });
      } else {
        let modifiedData = clonedProducts.filter(
          (e) => !(e.productId == productDetails._id && e.variantId == variant._id)
        );
        dispatch(fetchBagProducts(modifiedData));
        totalCalculation({ dispatch, reqObj: modifiedData });
      }
    }
  }
};

export const qtyHandler = ({ productDetails, variant, value, bag, dispatch }) => {
  let reqQty = Number(value);
  if (!isNaN(value)) {
    let clonedProducts = _.cloneDeep(bag?.products);
    let reqProduct = clonedProducts.find(
      (e) => e.productId == productDetails?._id && e.variantId == variant._id
    );
    if (reqProduct) {
      let qty = reqProduct.leftOverQuantity;
      if (qty == 'unlimited' || qty >= reqQty) {
        reqProduct.count = reqQty;
        let modifiedData = objectReplace(clonedProducts, reqProduct, 'variantId');
        dispatch(fetchBagProducts(modifiedData));
        totalCalculation({ dispatch, reqObj: modifiedData });
      } else {
        dispatch(
          snackbarInfo({ type: 'warning', open: true, message: `Only ${qty} are available` })
        );
      }
    }
  }
};

export const totalCalculation = ({ dispatch, reqObj }) => {
  if (!_.isEmpty(reqObj)) {
    let qty = 0;
    let amount = 0;
    reqObj.map((res) => {
      qty = qty + res.count;
      amount = amount + res.count * res.price;
    });
    if (qty > 0) {
      dispatch(fetchBagCalculation({ qty, amount }));
    } else {
      dispatch(fetchBagCalculation(null));
    }
  } else {
    dispatch(fetchBagCalculation(null));
  }
};

export const productFinalPriceHandler = ({ price, discount, discountType, gst, count }) => {
  let discountValue =
    discountType == 'percentage' ? (price * Number(discount)) / 100 : discount / count;
  let reqDiscountValue = Number(discountValue.toFixed(2));

  let priceWithGst = Number((price - reqDiscountValue).toFixed(2));
  let priceWithoutGst = Number((priceWithGst / (1 + gst)).toFixed(2));
  return { priceWithGst, priceWithoutGst };
};
