import React, { useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import MynyfyButton from './MynyfyButton';
import { CircularProgress } from '@mui/material';

const TopMargin = 10;
const BottomMargin = 10;

function MynyfyPDFGenerator({ htmlString, pdfName, btnName, containerStyle, style, customBtn }) {
  const [isLoading, setLoading] = useState(false);

  const generatePdf = async () => {
    try {
      setLoading(true);
      // Create an invisible iframe to render the HTML content
      let iframe = document.createElement('iframe');
      iframe.style.visibility = 'hidden';
      iframe.style.width = '794px'; // A4 width
      document.body.appendChild(iframe);

      let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
      iframedoc.body.innerHTML = htmlString;

      // Convert the HTML content to a canvas
      const canvas = await html2canvas(iframedoc.body, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');

      // Create a new jsPDF instance
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const pageHeight = pdfHeight - TopMargin - BottomMargin;

      // Calculate the height of the image in the PDF context
      const imgProps = pdf.getImageProperties(imgData);
      const imgWidth = pdfWidth;
      const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

      // Check if the image height is less than the page height
      if (imgHeight <= pageHeight) {
        pdf.addImage(imgData, 'PNG', 0, TopMargin, pdfWidth, imgHeight);
      } else {
        let heightLeft = imgHeight;
        let position = 0;

        // Loop through the content and add it to the PDF
        while (heightLeft > 0) {
          const canvasPage = document.createElement('canvas');
          canvasPage.width = canvas.width;
          canvasPage.height = Math.min(canvas.height, (canvas.width * pageHeight) / pdfWidth);

          const ctx = canvasPage.getContext('2d');
          ctx.drawImage(
            canvas,
            0,
            position * (canvas.width / pdfWidth),
            canvas.width,
            canvasPage.height,
            0,
            0,
            canvas.width,
            canvasPage.height
          );

          const imgPageData = canvasPage.toDataURL('image/png');

          pdf.addImage(imgPageData, 'PNG', 0, TopMargin, pdfWidth, pageHeight);
          heightLeft -= pageHeight;
          position += pageHeight;

          if (heightLeft > 0) {
            pdf.addPage();
          }
        }
      }

      // Save the PDF
      pdf.save(pdfName ? pdfName + '.pdf' : 'mynyfy.pdf');
      iframe.remove();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error generating PDF:', error);
    }
  };

  return isLoading ? (
    <CircularProgress size={20} thickness={6} disableShrink />
  ) : customBtn ? (
    <div onClick={() => generatePdf()}>{customBtn()}</div>
  ) : (
    <MynyfyButton
      label={btnName || 'Download'}
      onClick={() => generatePdf()}
      containerStyle={containerStyle}
      style={style}
    />
  );
}

export default MynyfyPDFGenerator;
